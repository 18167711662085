import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	constructor(private http: HttpClient) {}

	get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http.get(`${environment.SERVER_URL}${path}`, { params });
	}

	put(path: string, body: object = {}): Observable<any> {
		return this.http.put(`${environment.SERVER_URL}${path}`, body);
	}

	post(
		path: string,
		body: object = {},
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.http.post(`${environment.SERVER_URL}${path}`, body, {
			params,
			observe: "response",
		});
	}

	delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http.delete(`${environment.SERVER_URL}${path}`, { params });
	}
}
