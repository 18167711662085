import { Injectable } from "@angular/core";
import { UrlService } from "./url.service";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { HttpParams } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class UserService {
	constructor(private apiService: ApiService, private url: UrlService) {}

	getPractitionerList(payload: any): Observable<any[]> {
		return this.apiService.get(this.url.PRACTITIONER_lIST, payload);
	}

	getActionHistoryList(id: any): Observable<any[]> {
		return this.apiService.get(this.url.GET_ACTION_HISTORY.replace(":id", id));
	}

	getPatientList(payload: any): Observable<any[]> {
		return this.apiService.get(this.url.PATIENT_lIST, payload);
	}
	userCounts(): Observable<any[]> {
		return this.apiService.get(this.url.USER_COUNTS);
	}

	subscription(): Observable<any[]> {
		return this.apiService.get(this.url.SUBSCRIPTION);
	}

	getPractitionerDetail(id: any): Observable<any[]> {
		return this.apiService.get(this.url.PRACTITIONER_VIEW.replace(":id", id));
	}

	editPractitioner(id: any, payload: any): Observable<any[]> {
		return this.apiService.put(
			this.url.EDIT_PRACTITIONER.replace(":id", id),
			payload
		);
	}

	deletePractitionerPateint(id: any): Observable<any[]> {
		return this.apiService.delete(
			this.url.DELETE_PRACTITIONER.replace(":id", id)
		);
	}

	updateStatus(payload: any, id: any) {
		return this.apiService.put(
			`${this.url.UPDATE_STATUS.replace(":id", id)}`,
			payload
		);
	}

	verifyLicenseStatus(status: any, id: any) {
		return this.apiService.put(
			`${this.url.UPDATE_LICENSE_STATUS.replace(":id", id)}?status=${status}`
		);
	}

	unVerifyLicenseStatus(payload: any, status: any, id: any) {
		return this.apiService.put(
			`${this.url.UPDATE_LICENSE_STATUS.replace(":id", id)}?status=${status}`,
			payload
		);
	}

	getAppointment(payload: any, id: any): Observable<any[]> {
		return this.apiService.get(
			this.url.APPINTMENTS_LIST.replace(":id", id),
			payload
		);
	}

	getAllAppointment(payload: any): Observable<any[]> {
		return this.apiService.get(this.url.ALL_APPOINTMENTS, payload);
	}

	getSubscriptionById(id: any): Observable<any[]> {
		return this.apiService.get(this.url.GET_PLAN_BY_ID.replace(":id", id));
	}

	getTreatments() {
		let params = new HttpParams();
		params = params.set("isAll", true);
		return this.apiService.get(this.url.TREATMENTS, params);
	}

	getTechnique() {
		let params = new HttpParams();
		params = params.set("isAll", true);
		return this.apiService.get(this.url.TECHNIQUE, params);
	}

	addTreatments(payload: any): Observable<any[]> {
		return this.apiService.post(this.url.ADD_TREATMENTS, payload);
	}

	addTechnique(payload: any): Observable<any[]> {
		return this.apiService.post(this.url.ADD_TECHNIQUE, payload);
	}
	userImage(image: any) {
		return this.apiService.post(this.url.USER_IMAGE, image);
	}

	resetPassword(payload: any) {
		return this.apiService.put(this.url.RESET_PASSWORD, payload);
	}
	updateTreatments(payload: any) {
		return this.apiService.put(this.url.UPDATE_TREATMENTS, payload);
	}

	deleteTreatments(id: any): Observable<any[]> {
		let params = new HttpParams();
		params = params.set("expertiseId", id);
		return this.apiService.delete(this.url.DELETE_TREATMENTS, params);
	}
	deleteTechnique(id: any): Observable<any[]> {
		let params = new HttpParams();
		params = params.set("techId", id);
		return this.apiService.delete(this.url.DELETE_TECHNIQUE, params);
	}
	updateTechnique(payload: any, id: any) {
		const urlWithQuery = `${this.url.UPDATE_TECHNIQUE}?techId=${id}`;
		return this.apiService.put(urlWithQuery, payload);
	}

	addPlan(payload: any) {
		return this.apiService.post(this.url.ADD_PLAN, payload);
	}

	getFaqs() {
		return this.apiService.get(this.url.GET_FAQ);
	}

	updateFaqs(payload: any, id: any) {
		const urlWithQuery = `${this.url.UPDATE_FAQ}?faqId=${id}`;
		return this.apiService.put(urlWithQuery, payload);
	}
	addFaqs(payload: any): Observable<any[]> {
		return this.apiService.post(this.url.ADD_FAQ, payload);
	}

	deleteFaq(id: any): Observable<any[]> {
		let params = new HttpParams();
		params = params.set("faqId", id);
		return this.apiService.delete(this.url.DELETE_FAQ, params);
	}

	deletePlan(id: any) {
		return this.apiService.delete(`${this.url.DELETE_PLAN.replace(":id", id)}`);
	}

	getSubAdmin(): Observable<any[]> {
		return this.apiService.get(this.url.GET_SUBADMIN);
	}
	getSubAdminById(id: any): Observable<any[]> {
		return this.apiService.get(
			this.url.GET_PERMISSION_BY_ID.replace(":id", id)
		);
	}

	updateSubAdminById(id: any, payload: any): Observable<any[]> {
		return this.apiService.put(
			this.url.UPDATE_PERMISSION_BY_ID.replace(":id", id),
			payload
		);
	}

	deleteSubAdmin(id: any): Observable<any[]> {
		return this.apiService.delete(
			this.url.DELETE_PERMISSION.replace(":id", id)
		);
	}

	getAppointmentById(id: any): Observable<any[]> {
		let params = new HttpParams();
		params = params.set("appointmentId", id);
		return this.apiService.get(this.url.GET_APPOINTMENT_BT_ID, params);
	}

	updatePlan(payload: any, id: any) {
		// const urlWithQuery = `${this.url.UPDATE_PLAN}?planId=${id}`;
		return this.apiService.put(
			this.url.UPDATE_PLAN.replace(":id", id),
			payload
		);
	}

	getPlanById(id: any): Observable<any[]> {
		return this.apiService.get(this.url.USER_PLAN.replace(":id", id));
	}

	globalSettings(id: any, payload: any) {
		const urlWithQuery = `${this.url.POST_GLOBALSETTINGS}?settingId=${id}`;
		return this.apiService.put(urlWithQuery, payload);
	}

	getGlobalSettings(id: any) {
		let params = new HttpParams();
		params = params.set("settingId", id);
		return this.apiService.get(this.url.GET_GLOBALSETTINGS, params);
	}

	getAllIssue(payload: any): Observable<any[]> {
		return this.apiService.get(this.url.GET_SUPPORT, payload);
	}

	getReply(id: any) {
		const urlWithQuery = `${this.url.GET_REPLY_DETAIL.replace(":id", id)}`;
		return this.apiService.get(urlWithQuery);
	}

	postReply(payload: any, id: any) {
		const urlWithQuery = `${this.url.POST_REPLY.replace(":id", id)}`;
		return this.apiService.post(urlWithQuery, payload);
	}

	getCountry(): Observable<any[]> {
		return this.apiService.get(this.url.GET_COUNTRY);
	}

	getStates(id: any) {
		const urlWithQuery = `${this.url.GET_STATES.replace(":id", id)}`;
		return this.apiService.get(urlWithQuery);
	}
	getCity(id: any) {
		const urlWithQuery = `${this.url.GET_CITY.replace(":id", id)}`;
		return this.apiService.get(urlWithQuery);
	}

	postLocation(payload: any): Observable<any[]> {
		return this.apiService.post(this.url.POST_LOCATION, payload);
	}

	getLocation(): Observable<any[]> {
		return this.apiService.get(this.url.POST_LOCATION);
	}

	changeStatusLocation(id: any, status: any): Observable<any[]> {
		const urlWithQuery = `${this.url.CHANGE_STATUS_LOCATION.replace(
			":id",
			id
		)}?status=${status}`;
		return this.apiService.put(urlWithQuery);
	}

	deletLocation(id: any): Observable<any[]> {
		return this.apiService.delete(this.url.DELETE_LOCATION.replace(":id", id));
	}

	getDefaultPermission(): Observable<any[]> {
		return this.apiService.get(this.url.GET_DEFAULT_DATA);
	}

	addPermission(payload: any): Observable<any[]> {
		return this.apiService.post(this.url.ADD_PERMISSION, payload);
	}

	getAppFeedback(payload: any): Observable<any[]> {
		return this.apiService.get(this.url.GET_APP_FEEDBACK, payload);
	}

	getUserFeedback(payload: any): Observable<any[]> {
		return this.apiService.get(this.url.GET_USER_FEEDBACK, payload);
	}

	getTermsConditions(): Observable<any[]> {
		return this.apiService.get(this.url.GET_TERMS_CONDITIONS);
	}

	updateTermsConditions(payload: any): Observable<any[]> {
		return this.apiService.put(this.url.UPDATE_TERMS_CONDITIONS, payload);
	}

	getPrivacyPolicy(): Observable<any[]> {
		return this.apiService.get(this.url.GET_PRIVACY_POLICY);
	}

	updatePrivacyPolicy(payload: any): Observable<any[]> {
		return this.apiService.put(this.url.UPDATE_PRIVACY_POLICY, payload);
	}

	authorizeCalenderRegistration(code: any, state: any): Observable<any[]> {
		const urlWithQuery = `${this.url.AUTHORIZE_CALENDER_REGISTRATION}?code=${code}&state=${state}`;
		return this.apiService.post(urlWithQuery);
	}
}
