import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class UrlService {
	LOGIN: string = "/api/authenticate/login";
	FORGOT_PASSWORD: string = "/api/authenticate/forgetPassword";
	RESET_PASSWORD: string = "/api/authenticate/resetPassword";

	// user Module;
	PRACTITIONER_lIST: string = "/api/v1/users";
	PATIENT_lIST: string = "/api/v1/users";
	PRACTITIONER_VIEW: string = "/api/v1/userDetail/:id";
	EDIT_PRACTITIONER: string = "/api/v1/userDetail/:id";
	DELETE_PRACTITIONER: string = "/api/v1/user/:id";
	UPDATE_STATUS: string = "/api/v1/user/:id/approvalStatus";
	APPINTMENTS_LIST: string = "/api/v1/user/:id/appointments";
	ALL_APPOINTMENTS: string = "/api/v1/appointments";
	GET_APPOINTMENT_BT_ID: string = "/api/v1/appointment";
	GET_PLAN_BY_ID: string = "/api/v1/user/:id/userSubscriptionPlans";
	UPDATE_LICENSE_STATUS = "/api/v1/user/:id/updateLicense";
	GET_ACTION_HISTORY = "/api/v1/user/:id/statusChangeReasons";

	// dashboard Module;
	USER_COUNTS: string = "/api/v1/usersCounts";

	//  Subscription Module
	SUBSCRIPTION: string = "/api/v1/plan";
	ADD_PLAN: string = "/api/v1/plan";
	DELETE_PLAN: string = "/api/v1/plan/:id";
	UPDATE_PLAN: string = "/api/v1/plan/:id";
	USER_PLAN: string = "/api/v1/plan/:id";

	// Treatments

	TREATMENTS: string = "/api/v1/specialization";
	ADD_TREATMENTS: string = "/api/v1/specialization";
	DELETE_TREATMENTS: string = "/api/v1/specialization";
	UPDATE_TREATMENTS: string = "/api/v1/specialization";

	USER_IMAGE: string = "/api/v1/profileUpload";

	//Technique
	TECHNIQUE: string = "/api/v1/technique";
	ADD_TECHNIQUE: string = "/api/v1/technique";
	DELETE_TECHNIQUE: string = "/api/v1/technique";
	UPDATE_TECHNIQUE: string = "/api/v1/technique";

	// CMS
	GET_FAQ: string = "/api/v1/faqs";
	UPDATE_FAQ: string = "/api/v1/faqs";
	ADD_FAQ: string = "/api/v1/faqs";
	DELETE_FAQ: string = "/api/v1/faqs";

	//subAdmins
	GET_SUBADMIN: string = "/api/v1/user/admins";
	GET_DEFAULT_DATA: string = "/api/v1/admin/permissions";
	ADD_PERMISSION: string = "/api/v1/admin/permissions";
	GET_PERMISSION_BY_ID: string = "/api/v1/admin/:id/permissions";
	UPDATE_PERMISSION_BY_ID: string = "/api/v1/admin/:id/permissions";
	DELETE_PERMISSION: string = "/api/v1/admin/:id/permissions";

	// global setting
	GET_GLOBALSETTINGS: string = "/api/v1/globalSettings";
	POST_GLOBALSETTINGS: string = "/api/v1/globalSettings";

	//support
	GET_SUPPORT: string = "/api/v1/reportIssue";
	GET_REPLY_DETAIL: string = "/api/v1/reportIssue/:id";
	POST_REPLY: string = "/api/v1/replyIssues/User/:id";

	//Location
	GET_COUNTRY: string = "/api/v1/country";
	GET_STATES: string = "/api/v1/:id/states";
	GET_CITY: string = "/api/v1/:id/cities";
	POST_LOCATION: string = "/api/v1/serviceLocation";
	GET_LOCATION: string = "/api/v1/serviceLocation";
	DELETE_LOCATION: string = "/api/v1/serviceLocation/:id";
	CHANGE_STATUS_LOCATION: string = "/api/v1/serviceLocation/:id";

	// feedbacks
	GET_APP_FEEDBACK: string = "/api/v1/feedbacks";
	GET_USER_FEEDBACK: string = "/api/v1/user/feedback";

	//verification Email
	VERIFY_EMAIL: string = "/api/authenticate/verifyUser";

	//calender authorization
	AUTHORIZE_CALENDER_REGISTRATION: string = "/api/v1/cronofy/userProfile";

	//Terms & Conditions
	GET_TERMS_CONDITIONS: string = "/api/v1/termCondition";
	UPDATE_TERMS_CONDITIONS: string = "/api/v1/termCondition";

	//Privacy Policies
	GET_PRIVACY_POLICY: string = "/api/v1/privacyPolicy";
	UPDATE_PRIVACY_POLICY: string = "/api/v1/privacyPolicy";
}
